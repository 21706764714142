<template>
  <div class="invitation">
    <div class="vip" :style="`background: ${leveList[levelActive].bgcolor};`">
      <div class="lefttop" :style="`background: ${leveList[levelActive].leftbgColor}; color: var( --text-color);`">{{
        $t('lang.d164') }}</div>
      <div class="isupgrade" v-show="fundconfig.status == '2'">
        <img src="@/assets/imgsnowball/upgrade_icon.png" alt="">
      </div>
      <div class="viptext">
        <span class="viptext_line1" :style="`color:${leveList[levelActive].color}`"> {{
          $t(leveList[levelActive].level_text) }}</span>
        <div class="vipbutton"
          :style="{ background: leveList[levelActive].buttonBgColor, color: leveList[levelActive].buttonColor, opacity: 0.6 }"
          v-if="fundconfig.status == '2'">{{ $t('lang.h321') }}</div>
        <div class="vipbutton"
          :style="{ background: leveList[levelActive].buttonBgColor, color: leveList[levelActive].buttonColor }" v-else
          @click="payPopupOpenFunc">{{ $t('lang.h320') }}</div>
      </div>
      <img :src="leveList[levelActive].level_logo" alt="" />
    </div>
    <div class="yaoq">
      <p class="y_title">{{ $t('lang.swap80') }}</p>

      <div class="y_line1" v-if="parent_id > 0">{{ linkshow }}</div>
      <div class="y_line1" v-else>{{ $t('lang.swap371') }}</div>
      <div class="y_button copys" @click="copy" :data-clipboard-text="`${invite_linkleft}/#/?invite_code=${account}`"
        v-if="parent_id > 0">
        {{ $t('lang.d64') }}
      </div>
      <div class="y_button" style="opacity: 0.4" v-else>{{ $t('lang.d64') }}</div>
    </div>
    <div class="jilu">
      <p class="j_title">{{ $t('lang.swap372') }}</p>
      <div class="j_text" v-if="recommender != ''">
        <span>
          {{ recommender }}
        </span>

        <div class="j_text_right">
          <!-- <span>合伙</span> -->
          <span v-if="partnerlevel_info.team_level == 0">{{ $t('lang.h81') }}</span>
          <span v-else-if="partnerlevel_info.team_level == 1">{{ $t('lang.h82') }}</span>
          <span v-else-if="partnerlevel_info.team_level == 2">{{ $t('lang.h80') }}</span>
        </div>
      </div>
      <div class="j_text" @click="popupfunopen" style="justify-content: center" v-else>{{ $t('lang.d97') }}</div>

      <div class="j_sosuo">
        <span class="s_span">{{ $t('lang.swap63') }}</span>
        <div class="vant">
          <van-icon name="search" class="v_icon" />
          <input type="text" v-model="souoInput" class="van-field2" @input="sosuofun"
            :placeholder="`${$t('lang.swap362')}`" />
          <!-- <van-field type="text"
                           v-model="souoInput"
                           input-align="left"
                           @input="sosuofun"
                           :placeholder="`${$t('lang.swap362')}`"
                           class="van-field2" /> -->
        </div>
      </div>

      <div class="y_cont">
        <div class="c_wlist" v-if="chelist.length == 0">
          <img src="@/assets/imgsnowball/wujilu.png" alt="" />
          <p>{{ $t('lang.d57') }}</p>
        </div>
        <template v-else>
          <div class="list_title" style="opacity: 0.6">
            <div>{{ $t('lang.d170') }}</div>
            <div>{{ $t('lang.d171') }}</div>
            <div>{{ $t('lang.h79') }}</div>
            <div>{{ $t('lang.h80') }}</div>
          </div>


          <div class="c_li" v-for="(item, index) in chelist" :key="index">
            <div>
              {{ item.address }}
            </div>
            <div>
              <span style="color: var(--text-color)" v-if="item.team_level == 0">{{ $t('lang.h81') }}</span>
              <span style="color: rgba(164, 107, 255, 1)" v-else-if="item.team_level == 1">{{ $t('lang.h82') }}</span>
              <span style="color: rgba(48, 255, 252, 1)" v-else-if="item.team_level == 2">{{ $t('lang.h80') }}</span>
            </div>
            <div>{{ item.dr_partner - item.dr_genesis_partner }}</div>
            <div>{{ item.dr_genesis_partner }}</div>
          </div>

        </template>
      </div>
    </div>
    <van-popup v-model="popupshow" round>
      <div class="popup">
        <div class="p_main">
          <p class="m_title">{{ $t('lang.swap372') }}</p>
          <span> {{ $t('lang.swap373') }}</span>
          <van-field type="text" v-model="fromInput" input-align="left" placeholder="" class="van-field" />
          <div class="m_button" @click="postucbin">{{ $t('lang.d78') }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="paystatusShow" round closeable>
      <div class="paystatusShow">
        <img src="@/assets/imgsnowball/paystatus.png" alt="">
        <span>{{ $t('lang.h241') }}</span>
      </div>
    </van-popup>
    <van-popup v-model="payPopup" position="bottom" round closeable>
      <div class="paypop">
        <div class="title">
          {{ $t('lang.h117') }}
        </div>
        <div class="paypop_cont">
          <div class="paypop_checks">
            <div class="paypop_checks_box">
              <span> {{ fundconfig.fund_amount }} HAP / {{ $t('lang.h324') }}</span>
              <div class="checkbox">
                <img src="@/assets/imgsnowball/check_true.png" alt="">
              </div>
            </div>

          </div>
          <div style="color: rgba(34, 34, 34, 1); font-size: 14px; font-weight: bold; margin-bottom: 10px;">{{
            $t('lang.h325') }}</div>
          <div class="stepper"><van-stepper class="stepper_box" :placeholder="$t('lang.h326')" v-model="amountValue"
              input-width="225px" integer /></div>
          <div
            style="margin-top: 25px; color: rgba(34, 34, 34, 1); font-size: 14px; font-weight: bold; margin-bottom: 10px;">
            {{ $t('lang.h327') }}</div>
          <div style="color: rgba(0, 0, 0, 1); font-size: 32px; font-weight: bold; margin-bottom: 20px;">{{
            fundconfig.fund_amount * amountValue }} HAP</div>
          <div style="color: #81B5FE; font-size: 12px; margin-bottom: 43px;">{{ $t('lang.h243') }}</div>
          <div class="banlace">
            <img src="@/assets/imgsnowball/banlce.png" alt="">
            <div class="banlace_box">
              <div style="font-size: 16px; color: rgba(0, 0, 0, 1); font-weight: 700; margin-bottom: 7px;">{{
                $t('lang.h118') }}</div>
              <div style="display: flex;">
                <div style="width: 80px;">HAP{{ $t('lang.h119') }}：</div> {{ walletBanlace.usable }}
              </div>

            </div>
          </div>
          <div class="button" v-loading="payLoading" @click="circulationfundjoin">{{ $t('lang.h120') }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import { mapState } from 'vuex';
import { user, homedata, circulation, happydex, web3_api } from '@/api/index';


export default {
  data() {
    return {
      partnerlevel_info: {},
      recommender: '',
      levelActive: 0,
      loading1: false,
      finished1: false,
      payPopup: false,
      payLoading: false,
      popupshow: false,
      paystatusShow: false,
      walletBanlace: {},
      gerenmsg: {},
      chelist: [],
      invite_linkleft: '',
      fromInput: '',
      souoInput: '',
      parent_id: 0,
      checkIndex: 0,
      LoadingPopup: false,
      fundconfig: {},
      amountValue: 1,
      leveList: [
        {
          level_text: 'lang.h55',
          color: 'rgba(255, 255, 255, 1)',
          bgcolor: 'linear-gradient(90deg, rgba(118, 130, 176, 1) 0%, rgba(66, 75, 107, 1) 100%)',
          level_logo: require('@/assets/img/level_logo1.png'),
          leftbgColor: 'rgba(171, 204, 235, 1)',
          buttonBgColor: '#1D2131',
          buttonColor: '#949494',
        },
        {
          level_text: 'lang.h56',
          color: 'rgba(0, 76, 89, 1)',
          bgcolor: 'linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%)',
          level_logo: require('@/assets/img/level_logo3.png'),
          leftbgColor: 'rgba(14, 124, 143, 1)',
          buttonBgColor: '#6A60FF',
          buttonColor: '#FFFFFF',
        },
        {
          level_text: 'lang.h57',
          color: 'rgba(199, 120, 26, 1)',
          bgcolor: 'linear-gradient(148.48deg, rgba(254, 228, 182, 1) 0%, rgba(253, 205, 121, 1) 59.21%, rgba(248, 212, 140, 1) 100%)',
          level_logo: require('@/assets/img/level_logo2.png'),
          leftbgColor: 'rgba(199, 120, 26, 1)',
          buttonBgColor: '#C7781A',
          buttonColor: '#FFFFFF',
        },
      ],
    };
  },

  created() {
    this.init();
  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account', 'lang']),
    linkshow() {
      var link = `${this.invite_linkleft}/#/?invite_code=${this.account}`;
      const head = link.slice(0, 22);
      const tail = link.slice(link.length - 5);
      return head + '****' + tail;
    },
  },

  methods: {
    checkIndexFun(index) {
      this.checkIndex = index;
    },
    gosnowguan() {
      this.$router.push({ name: 'snowguan' });
    },
    popupfunopen() {
      this.popupshow = true;
    },
    popupfunclose() {
      this.popupshow = false;
    },
    payPopupOpenFunc() {
      if (this.fundconfig.status == '0') return this.$toast(this.$t('lang.h244'));
      this.payPopup = true
    },
    init() {
      if (this.account) {
        this.recommender = ''
        this.chelist = [];
        this.getdata();
        this.getinfo();

        this.geturl();

        this.ucrecommender();
        this.partnerlevelinfo();
        this.partnerparentlevelinfo();
        this.circulationfundconfig();
        this.getwalletBanlace()
      }

    },
    async circulationfundjoin() {
      if (this.payLoading) return;
      this.payLoading = true
      let sign = null
      try {
        sign = await web3_api.signMessage('Circulation Fund Join', this.account)
      } catch (error) {
        this.payLoading = false
        return
      }
      let data = {
        num: this.amountValue,
        signature: sign
      }
      circulation.circulationfundjoin(data, this.account).then(res => {
        if (res.code == 200) {
          this.payPopup = false

          this.paystatusShow = true
          this.init()
        }
        this.$toast(res.msg)
        this.payLoading = false
      })
    },
    getwalletBanlace() {
      circulation.walletinfobykey({ token_key: 'HAPB' }, this.account).then(res => {
        if (res.code == 200) {
          this.walletBanlace = res.data
        }
      })
    },
    circulationfundconfig() {
      circulation.circulationfundconfig(this.account).then(res => {
        if (res.code == 200) {
          this.fundconfig = res.data
        }

      })
    },
    ucrecommender() {
      user.ucrecommender(this.account).then(res => {
        if (res.code == 200) {
          this.recommender = res.data.recommender;
        }
      });
    },
    partnerparentlevelinfo() {
      happydex.partnerparentlevelinfo(this.account).then(res => {
        this.partnerlevel_info = res.data.level_info;
      });
    },
    partnerlevelinfo() {
      happydex.partnerlevelinfo(this.account).then(res => {
        if (res.code == 200) {
          this.levelActive = res.data.level_info.team_level || 0;
        }
      });
    },

    sosuofun() {
      let data = {

        address: this.souoInput,
      };
      user.getucchildrenlist(data, this.account).then(res => {
        this.chelist = res.data.list;
      });
    },
    copy() {
      if (this.gerenmsg.invite_code == '') return this.$toast(this.$t('lang.d180'));
      this.invite_link = this.account;
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap300'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap301'));
          clipboard.destroy(); // 释放内存
        });
      }
    },

    getdata() {

      user.getucchildrenlist({}, this.account).then(res => {

        this.chelist = res.data || [];


      });
    },

    getinfo() {
      user.getucinfo(this.account).then(res => {
        this.gerenmsg = res.data;
        this.parent_id = this.gerenmsg.parent_id;
      });
    },


    geturl() {
      homedata.gettokenimgurl().then(res => {
        this.invite_linkleft = res.data.domain;
      });
    },
    postucbin() {
      this.popupshow = false;
      let data = {
        parent_addr: this.fromInput,
      };
      user.postucbind(data, this.account).then(res => {
        this.$toast(res.msg);
        this.init();
      });
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.paystatusShow {

  opacity: 1;
  border-radius: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 298px;
  height: 217px;
  font-size: 18px;
  color: #222222;
  font-weight: bold;

  span {
    margin-top: 17px;
  }

  img {
    width: 128px;
    height: 119px;
  }
}

.LoadingPopup {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .LoadingPopup_text {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 18px;
    color: rgba(34, 34, 34, 1);
  }

  .status2 {
    img {
      width: 60px;
      height: 60px;
    }

    .payPopup_box_button {
      margin-top: 120px;
      width: 335px;
      height: 60px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(69, 131, 255, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18px;
      color: var(--text-color);
    }
  }
}

.paypop {
  width: 100%;
  background: #fff;

  .stepper {
    width: 100%;

    .stepper_box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    /deep/.van-stepper__input {
      width: 225px;
      height: 38px;
      opacity: 1;
      border-radius: 7px;
      background: #fff;
      border: 1px solid rgba(217, 217, 217, 1);
    }

    /deep/.van-stepper__minus {
      width: 38px;
      height: 38px;
      border-radius: 7px;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%), rgba(109, 199, 253, 1);
    }

    /deep/.van-stepper__plus {
      width: 38px;
      height: 38px;
      border-radius: 7px;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%), rgba(109, 199, 253, 1);
    }
  }

  .title {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 19px 20px;
    color: rgba(34, 34, 34, 1);
    border-bottom: 1px solid rgba(242, 242, 242, 1);
  }

  .paypop_cont {
    padding: 40px 21px;

    .button {
      margin-top: 23px;
      height: 48px;
      opacity: 1;
      border-radius: 110px;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      color: #000;
    }

    .banlace {
      padding-bottom: 7px;
      border-bottom: 1px solid rgba(242, 242, 242, 1);
      display: flex;

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .banlace_box {
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
      }
    }

    .paypop_checks {
      width: 100%;

      .paypop_checks_box {
        padding: 14px 19px;
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;

        border: 1px solid rgba(230, 230, 230, 1);
        margin-bottom: 14px;

        .checkbox {
          width: 14px;
          height: 14px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.list_box {
  margin-bottom: 5rem;
  max-height: 330px;
  overflow-y: scroll;

}

.list_title {
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--line-bg-color);

  div {
    width: 30%;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: var(--text-color) !important;
  }
}

.invitation {
  padding-top: 20px;
  width: 100%;
  text-align: center;
  background: var(--bg-color);

  .vip {
    margin: 0 auto;
    margin-bottom: 22px;
    position: relative;
    width: 337px;
    height: 134px;
    border-radius: 15px;

    background-size: contain;

    img {
      width: 120px;
      height: 83.45px;
      position: absolute;
      right: 14px;
      top: 25px;
    }

    .isupgrade {
      position: absolute;
      top: -20px;
      left: 94px;
      width: 23px;
      height: 23px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .vipbutton {
      margin-top: 12px;
      width: 60px;
      height: 29px;
      opacity: 1;
      border-radius: 6px;
      background: rgba(106, 96, 255, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(255, 255, 255, 1);
    }

    .viptext {
      position: absolute;
      top: 43px;
      left: 15px;
      display: flex;
      flex-direction: column;

      .viptext_line1 {
        font-size: 28px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 33.6px;
        color: rgba(14, 129, 245, 1);
      }

      .viptext_line2 {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(0, 0, 0, 1);
      }
    }

    .righttop {
      position: absolute;
      top: 14px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 30px;
      opacity: 1;
      border-radius: 15px 0 0 15px;
      background: rgba(189, 227, 255, 1);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(81, 142, 243, 1);
    }

    .lefttop {
      position: absolute;
      top: 6px;
      left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 68px;
      min-height: 23px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(0, 0, 0, 1);

      background: rgba(171, 204, 235, 1);
      border-radius: 15px 0 15px 0;
    }
  }

  .level2 {
    background: url(../../assets/img/level2_bg.png) no-repeat !important;
    background-size: contain !important;
  }

  .yaoq {
    width: 90%;
    margin: 0 auto;

    padding: 20px;
    border-radius: 20px;
    opacity: 1;
    background: var(--box-bg-color);
    box-shadow: 0 0 12px 0 #080a1b1a;
    text-align: center;

    .y_line1 {
      width: 100%;
      height: 52px;
      border-radius: 110px;
      text-align: center;
      line-height: 52px;
      color: rgba(188, 190, 193, 1);
      background: var(--line-bg-color);
    }

    .y_title {
      color: var(--text-color);
      font-size: 20px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .y_button {
      margin-top: 30px;
      width: 100%;
      height: 52px;
      border-radius: 110px;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
      box-shadow: 0 0 12px 0 #080a1b1a;
      text-align: center;
      line-height: 52px;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }

  .jilu {
    width: 100%;

    border-radius: 20px;
    margin-top: 20px;
    background: var(--box-bg-color);
    padding: 21px;

    .j_title {
      text-align: left;
      color: var(--text-color);
      font-size: 18px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .j_text {
      width: 100%;

      padding: 20px 13px;
      opacity: 1;
      border-radius: 12px;

      border: 1px solid var(--line-bg-color);
      box-shadow: 0 0 12px 0 #080a1b1a;
      color: var(--text-color);
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      display: flex;
      align-items: center;
      justify-content: space-between;

      .j_text_right {
        color: var(--text-color);
        padding-left: 16px;
        border-left: 1px solid var(--line-bg-color);
      }
    }

    .j_sosuo {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .s_span {
        color: var(--text-color);
        font-size: 18px;
        font-weight: 700;
        font-family: 'PingFang';
      }
    }
  }
}

.van-field {
  width: 228px;
  margin: 0 auto;

  height: 48px;
  border-radius: 4px;
  font-weight: bold;
  background: var(--line-bg-color) !important;
  padding: 14px !important;

  /deep/ .van-field__control {
    font-size: 14px;
    color: var(--text-color);
  }
}

.vant {
  width: 206px;
  position: relative;
  background: var(--line-bg-color);
  border-radius: 20px;
  height: 34px;

  .v_icon {
    position: absolute;
    left: 15px;
    top: 11px;
  }

  .van_sosuo {
    float: right;
    padding: 0;
    color: var(--text-color);
    width: 206px;
    height: 38px;

    border: none;
    background: none;
    box-shadow: 0 0 12px 0 #080a1b1a;
  }
}

.y_cont {
  width: 100%;
  margin-top: 30px;
  color: var(--text-color);

  .c_li {
    border-bottom: 1px solid var(--line-bg-color);
    height: 43px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      width: 30%;
      text-align: center;
    }
  }
}

/deep/.van-popup {
  background: none !important;
}

.popup {
  background: none !important;
  border: none !important;

  .p_main {
    width: 288px;

    border-radius: 10px;
    padding: 10px;
    background: var(--box-bg-color);

    .m_button {
      width: 228px;
      height: 48px;
      border-radius: 84px;
      margin: 0 auto;
      margin-top: 20px;
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
      line-height: 48px;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
    }

    .m_title {
      color: var(--text-color);
      font-size: 20px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
    }

    span {
      color: var(--text-color);
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      float: left;
      margin-bottom: 20px;
    }
  }
}

.popupx {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.van-field2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 !important;
  padding-top: 5px !important;
  color: #000;
  width: 170px;
  height: 34px;

  border: none;
  background: none !important;

  /deep/ .van-field__control {
    font-size: 14px;
    color: var(--text-color);
  }
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
  }

  p {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.popup {
  width: 100%;

}
</style>