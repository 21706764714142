import request from '../utils/request';
export default {
    circulationconfig(address) {
        return request.request({
            url: '/circulation/config',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    circulationorderpublish(data,address) {
        return request.request({
            url: '/circulation/order/publish',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data:data
        })
    },
    circulationordersellzone(data,address) {
        return request.request({
            url: '/circulation/order/sell-zone',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    circulationordercancel(data,address) {
        return request.request({
            url: '/circulation/order/cancel',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data:data
        })
    },
    circulationorderbuyzone(data,address) {
        return request.request({
            url: '/circulation/order/buy-zone',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    circulationwallettodayreward(address) {
        return request.request({
            url: '/circulation/wallet/today-reward',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    circulationwalletrecordlist(data,address) {
        return request.request({
            url: '/circulation/wallet/record-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    circulationwalletasset(address) {
        return request.request({
            url: '/circulation/wallet/asset',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    circulationuserchildrenlist(address) {
        return request.request({
            url: '/circulation/user/children-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },


    circulationuserinfo(address) {
        return request.request({
            url: '/circulation/user/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    circulationuserorderlist(data,address) {
        return request.request({
            url: '/circulation/user/order-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    circulationuserstorelist(data,address) {
        return request.request({
            url: '/circulation/user/store-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    walletinfobykey(data,address,language) {
        return request.request({
            url: '/wallet/info-by-key',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    circulationfundinfo(address) {
        return request.request({
            url: '/circulation/fund/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    circulationfundconfig(address) {
        return request.request({
            url: '/circulation/fund/config',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    circulationfundjoin(data,address) {
        return request.request({
            url: '/circulation/fund/join',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data:data
        })
    },
    circulationfundredeem(data,address) {
        return request.request({
            url: '/circulation/fund/redeem',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data:data
        })
    },
    
    circulationusermaxzone(address) {
        return request.request({
            url: '/circulation/user/max-zone',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
}