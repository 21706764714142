<template>
  <div class="nftcenter">
    <div class="recruit_title">
      <div style="transform: rotate(90deg); color: #fff" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
      <div></div>
    </div>

    <div class="nftcenter_sum" v-loading="loading">
      <div class="flex" style="align-items: start">
        <div class="sumbox">
          <div class="sumbox_title">{{ $t('lang.h101') }}</div>
          <div class="sumbox_num"><span>{{ hold_summary.remain_cp }}</span> <span style="font-size: 12px">$</span></div>
        </div>
        <img src="@/assets/imgsnowball/sumbox_img.png" class="sumbox_img" alt="" />
      </div>
      <div class="flex">
        <div class="flex" style="transform: scale(0.8) translateX(-30px)">
          <div class="sumbox" style="margin-right: 23px">
            <div class="sumbox_title">{{ $t('lang.h102') }}</div>
            <div class="sumbox_num"><span>{{ hold_summary.today_cp_reward }}</span> <span style="font-size: 12px">GOC</span></div>
          </div>
          <div class="sumbox">
            <div class="sumbox_title">{{ $t('lang.h103') }}</div>
            <div class="sumbox_num"><span>{{ hold_summary.today_share_reward }}</span> <span style="font-size: 12px">HAP</span></div>
          </div>
        </div>
        <div class="button" @click="gopath('nftbill')">{{ $t('lang.h104') }}</div>
      </div>
    </div>
    <div class="lookboxs">
      <div class="lookbox">
        <div class="abs_look" @click="gopath('user')">{{ $t('lang.h105') }}</div>
        <div class="box">
          <div class="box_num">{{ summaryinfo.self_cp }}</div>
          <img src="@/assets/imgsnowball/box1.png" alt="" />
          <div class="box_title">{{ $t('lang.h106') }}</div>
        </div>
      </div>
      <div class="lookbox">
        <div class="abs_look" style="background: rgba(182, 133, 255, 1);" @click="gopath('engbill',0)">{{ $t('lang.h105') }}</div>
        <div class="box"
          style="background: linear-gradient(0deg, rgba(182, 133, 255, 0.4) 0%, rgba(13, 8, 26, 0) 100%)">
          <div class="box_num">{{ summaryinfo.max_zone_cp }}</div>
          <img src="@/assets/imgsnowball/box2.png" alt="" />
          <div class="box_title">{{ $t('lang.h107') }}</div>
        </div>
      </div>
      <div class="lookbox">
        <div class="abs_look" style="background: rgba(97, 144, 255, 1);" @click="gopath('engbill',1)">{{ $t('lang.h105') }}</div>
        <div class="box" style="background: linear-gradient(0deg, rgba(97, 144, 255, 0.4) 0%, rgba(13, 8, 26, 0) 100%)">
          <div class="box_num">{{ summaryinfo.other_zone_cp }}</div>
          <img src="@/assets/imgsnowball/box3.png" alt="" />
          <div class="box_title">{{ $t('lang.h108') }}</div>
        </div>
      </div>
    </div>
    <div class="dotdbox">
      <div class="dotdbox_title">{{ $t('lang.h109') }}</div>
      <div class="dotdbox_cont">
        <div class="dotdbox_cont_level">
          <div class="level_index">2</div>
          <img class="level_logo" src="@/assets/imgsnowball/dotd_level2.png" alt="" />
          <div class="level_pop">{{ ranklist.startlist[1]?.share_cp }}</div>
          <div class="level_user">{{ ranklist.startlist[1]?.address }}</div>
        </div>
        <div class="dotdbox_cont_level" style="margin-bottom: 30px">
          <div class="level_index">1</div>
          <img class="level_logo" src="@/assets/imgsnowball/dotd_level1.png" alt="" />
          <div class="level_pop">{{ ranklist.startlist[0]?.share_cp }}</div>
          <div class="level_user">{{ ranklist.startlist[0]?.address }}</div>
        </div>
        <div class="dotdbox_cont_level" style="margin-top: 20px">
          <div class="level_index">3</div>
          <img class="level_logo" src="@/assets/imgsnowball/dotd_level3.png" alt="" />
          <div class="level_pop">{{ ranklist.startlist[2]?.share_cp }}</div>
          <div class="level_user">{{ ranklist.startlist[2]?.address }}</div>
        </div>
      </div>
    </div>
    <div class="dotdlist">
      <div class="dotdlist_li" v-for="(item, index) in ranklist.endlist" :key="index">
        <div class="dotdlist_li_index">{{ index + 4 }}</div>
        <div class="dotdlist_li_box">
          <div class="dotdlist_li_box_left">{{ item?.address }}</div>
          <div class="dotdlist_li_box_right">{{ item?.share_cp }}</div>
        </div>
      </div>
    </div>
    <div class="functional_region" id="myElementId">
      <div class="region">
        <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
          :class="index == regionIndex ? 'special' : ''">
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
    <div class="nftlist" v-show="regionIndex == 0">
      <div class="nftlist_li" v-for="(item, index) in nftlistdata" :key="index">
        <div class="nftlist_li_cont">
          <img :src="basicUrlData + item.logo" alt="" class="li_img" />
          <div class="li_right">
            <div class="li_right_name">
              <span>{{ item.name }}</span>
              <span>({{ item.hold_limit }}/{{ item.hold_num }})</span>
            </div>
            <van-rate v-model="item.hold_num" readonly :size="10" :count="item.hold_limit" color="rgba(53, 250, 252, 1)"
              void-icon="star" void-color="rgba(112, 112, 112, 1)" />
            <div class="li_right_pop">{{ $t('lang.h110') }}{{ item.price }}</div>
            <div class="li_right_pop" style="color: rgba(122, 255, 244, 1);">{{ $t('lang.h111') }}{{ item.production }}</div>
          </div>
        </div>
        <div class="nftlist_li_button button_active" v-if="item.can_buy" @click="paypopFun(item)">{{ $t('lang.h112') }}</div>
        <div class="nftlist_li_button" v-else-if="!item.can_buy&&item.hold_num ==0">{{ $t('lang.h113') }}</div>
        <div class="nftlist_li_button button_active" v-else style="opacity: 0.6;">{{ $t('lang.h112') }}</div>
      </div>
    </div>
    <div class="nftlist" v-show="regionIndex == 1">
      <van-list v-model="holdlistIng.loading" :finished="holdlistIng.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoadholdIng">
        <div class="nftlist_li" v-for="(item, index) in holdlistIng.list" :key="index" style="padding-top: 11px">
          <div class="nftlist_li_cont">
            <img :src="basicUrlData + item.nft_logo" alt="" class="li_img" />
            <div class="li_right" style="flex: 1">
              <div class="li_right_name">{{ $t('lang.h111') }}{{ item.production }}</div>
              <div class="li_right_name" style="font-size: 12px; color: rgba(255, 255, 255, 0.6); font-weight: 100; margin-bottom: 8px;">{{ $t('lang.h142') }} : {{ item.created_at }}</div>
              <div class="poptuct" style="margin-bottom: 8px;">
                <div style="color: rgba(53, 250, 252, 1); margin-right: 62px">
                  {{ $t('lang.h114') }} <br />
                  {{ item.production - item.remain_amount }}
                </div>
                <div>
                  {{ $t('lang.h115') }} <br />
                  {{ item.remain_amount }}
                </div>
              </div>
              <div>
                <div style="font-size: 12px; margin-bottom: 5px;display: flex; align-items: center; justify-content: space-between;"><span>{{ $t('lang.h116') }}</span> <span style="color: rgba(179, 136, 255, 1);">{{ $t('lang.h143') }}</span></div>
                <van-progress :percentage="productionCom(item.production, item.remain_amount)" stroke-width="8"
                  color="rgba(53, 250, 252, 1)" track-color="none" style="border: 1px solid rgba(30, 83, 84, 1)"
                  :show-pivot="false" />

              </div>
            </div>
          </div>
        </div>
      </van-list>

    </div>
    <div class="nftlist" v-show="regionIndex == 2">
      <van-list v-model="holdlistEnd.loading" :finished="holdlistEnd.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoadholdEnd">
        <div class="nftlist_li" v-for="(item, index) in holdlistEnd.list" :key="index" style="padding-top: 11px">
          <div class="nftlist_li_cont">
            <img :src="basicUrlData + item.nft_logo" alt="" class="li_img" />
            <div class="li_right" style="flex: 1">
              <div class="li_right_name">{{ $t('lang.h111') }}{{ item.production }}</div>
              <div class="li_right_name" style="font-size: 12px; color: rgba(255, 255, 255, 0.6); font-weight: 100; margin-bottom: 8px;">{{ $t('lang.h142') }} : {{ item.created_at }}</div>
              <div class="poptuct" style="margin-bottom: 8px;">
                <div style="color: rgba(53, 250, 252, 1); margin-right: 62px">
                  {{ $t('lang.h114') }} <br />
                  {{ item.production - item.remain_amount }}
                </div>
                <div>
                  {{ $t('lang.h115') }} <br />
                  {{ item.remain_amount }}
                </div>
              </div>
              <div>
                <div style="font-size: 12px; margin-bottom: 5px;display: flex; align-items: center; justify-content: space-between;"><span>{{ $t('lang.h116') }}</span> <span>{{ $t('lang.h144') }}</span></div>
                <van-progress :percentage="productionCom(item.production, item.remain_amount)" stroke-width="8"
                  color="rgba(53, 250, 252, 1)" track-color="none" style="border: 1px solid rgba(30, 83, 84, 1)"
                  :show-pivot="false" />

              </div>
            </div>
          </div>
        </div>
      </van-list>

    </div>
    <van-popup v-model="payshow" position="bottom" round closeable>
      <div class="paypop">
        <div class="title">
          {{ $t('lang.h117') }}
        </div>
        <div class="paypop_cont">
          <div class="paypop_checks">
            <div class="paypop_checks_box" @click="checkdataFun('0')" style="opacity: 0.6;">
              <span>≈ {{ roundToInteger(nftItemdata.price/ banlace.hap.usd_price)  }} HAP</span>
              <div class="checkbox">
                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '0'">
                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
              </div>
            </div>
            <div class="paypop_checks_box" @click="checkdataFun('1')">
              <span>≈  {{roundToInteger((nftItemdata.price * (1 - nftItemdata.hapc_rate))/ banlace.hap.usd_price)  }} HAP + {{roundToInteger((nftItemdata.price * nftItemdata.hapc_rate)/ banlace.hapc.usd_price)  }} HAPC</span>
              <div class="checkbox">
                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '1'">
                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
              </div>
            </div>
          </div>
          <div class="banlace">
            <img src="@/assets/imgsnowball/banlce.png" alt="">
            <div class="banlace_box">
              <div style="font-size: 16px; color: rgba(0, 0, 0, 1); font-weight: 700; margin-bottom: 7px;">{{ $t('lang.h118') }}</div>
              <div style="display: flex;">
                <div style="width: 80px;">HAP{{ $t('lang.h119') }}：</div> {{ banlace.hap.usable }}
              </div>
              <div style="display: flex;">
                <div style="width: 80px;">HAPC{{ $t('lang.h119') }}：</div> {{ banlace.hapc.usable }}
              </div>
            </div>
          </div>
          <div class="button" @click="paybuyFun">{{ $t('lang.h120') }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="paystatus" position="bottom" round >
      <div class="paystatus">
           <img src="@/assets/imgsnowball/paystatus_success.png" alt="">
           <div class="paystatus_text">{{ $t('lang.d195') }}</div>
           <van-button type="primary" round class="paystatus_button" @click="paystatusFun">{{ $t('lang.d197') }}</van-button>
      </div>
      </van-popup>
  </div>
</template>

<script>
import { nft } from '../../api/index';
import { mapState } from 'vuex';
import basicUrl from "@/config/configUrl/configUrl";
import Web3 from 'web3';

export default {
  data() {
    return {
      paystatus:false,
      loading:true,
      functionOption: [{ name: 'lang.h121' }, { name: 'lang.h122' }, { name: 'lang.h123' }],
      regionIndex: '0',
      value: 1,
      slidervalue: 20,
      nftlistdata: [],
      nftItemdata: {},
      basicUrlData: '',
      payshow: false,
      checkdata: '1',
      banlace: {
        hap: {},
        hapc: {}
      },
      hold_summary:{},
      ranklist: {
        startlist:[],
        endlist:[],
      },
      summaryinfo:{},
      holdlistIng: {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          page_size: 10,
          status: '1'
        }

      },
      holdlistEnd: {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          page_size: 10,
          status: '0'
        }

      }

    };
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {

      this.init()
    },
    lang() {
      this.init()
    }
  },
  components: {},
  created() {
    this.init()
  },
  mounted() { },
  methods: {
    init() {
      if (this.account) {
        this.loading = true
        this.basicUrlData = basicUrl.http_url
        this.holdlistIng = {
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            page_size: 10,
            status: '1'
          }

        }
        this.holdlistEnd = {
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            page_size: 10,
            status: '0'
          }

        }
        this.nftlist()
        this.getbanlace()
        this.nftholdlistiIng()
        this.nftholdlistiEnd()
        this.nftrankinglist()
        this.nftsummaryinfo()
        this.$nextTick(()=>{
        
        })
      }

    },
    paystatusFun(){
          this.paystatus =  false
    },
    nftsummaryinfo(){
          nft.nftsummaryinfo(this.account).then(res=>{
                   this.summaryinfo  = res.data?.team_info
                   this.hold_summary = res.data?.hold_summary
                   this.loading = false
          })
    },
    productionCom(production, remain_amount) {
      return ((production - remain_amount) / production) * 100
    },
    roundToInteger(num) {
    return  parseFloat(num).toFixed(3);
},
    onLoadholdIng() {
      if(this.account){
        this.nftholdlistiIng()
      }
      
    },
    onLoadholdEnd() {
      if(this.account){
        this.nftholdlistiEnd()
      }
      
    },
    nftrankinglist() {
      nft.nftrankinglist(this.account).then(res => {
        if (res.data != null || res.data.length > 0) {
          let result = res.data.slice(0, 3);
          this.ranklist.endlist = res.data.slice(3);
          let filler = {
            address:'---',
            share_cp:'---'
          }
          // 如果不足三项，则补全
          while (result.length < 3) {
            result.push(filler);
          }
          this.ranklist.startlist = result
        }

      })
    },
    nftholdlistiIng() {

      nft.nftholdlist(this.holdlistIng.info, this.account).then(res => {
        if (res.data != null || res.data.length > 0) {
          if (this.holdlistIng.info.page == 1) {
            this.holdlistIng.list = res.data
          } else {
            this.holdlistIng.list = this.holdlistIng.list.concat(res.data)
          }
          if (res.data.length < this.holdlistIng.info.page_size) {
            this.holdlistIng.loading = true
            this.holdlistIng.finished = true
          } else {
            this.holdlistIng.info.page++
          }

        }

      })
    },
    nftholdlistiEnd() {

      nft.nftholdlist(this.holdlistEnd.info, this.account).then(res => {
        if (res.data != null || res.data.length > 0) {
          if (this.holdlistEnd.info.page == 1) {
            this.holdlistEnd.list = res.data
          } else {
            this.holdlistEnd.list = this.holdlistEnd.list.concat(res.data)
          }
          if (res.data.length < this.holdlistEnd.info.page_size) {
            this.holdlistEnd.loading = true
            this.holdlistEnd.finished = true
          } else {
            this.holdlistEnd.info.page++
          }

        }

      })
    },
    getbanlace() {
      nft.walletinfobykey({ token_key: 'HAPB' }, this.account).then(res => {
        this.banlace.hap = res.data
      })
      nft.walletinfobykey({ token_key: 'HAPC' }, this.account).then(res => {
        this.banlace.hapc = res.data
      })
    },
    checkdataFun(index) {
      if (index == '0') {
        return;
      }
      this.checkdata = index
    },
    paypopFun(item) {
      this.nftItemdata = item
      this.payshow = true
    },
    async paybuyFun() {
     
      let sign = null
      try {
        let web3 = new Web3(window.ethereum);
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('NFT Buy'), this.account);
      } catch (error) {
        return;
      }


      let data = {
        nft_id: this.nftItemdata.id,
        type: parseInt(this.checkdata),
        signature: sign
      }
      nft.nftholdbuy(data, this.account).then(res => {
                if(res.code == 200){
                    this.init()
                    this.paystatus = true
                    this.payshow = false
                }
                this.$toast(res.msg)
      })
    },
    nftlist() {
      nft.nftlist(this.account).then((res) => {
        if (res.code == 200) {
          this.nftlistdata = res.data
        }
      });
    },
    toprouter() {
      this.$router.go(-1);
    },

    switchovers(index) {
      this.regionIndex = index;

      switch (this.regionIndex) {
        default:
          break;
      }

    },
    gopath(router,i) {
      switch (router) {
        case 'nftbill':
          this.$router.push('/nftbill')
          break;
        case 'engbill':
         
          this.$router.push({path:'engbill',query:{regionIndex:i}})
          break;
        case 'user':
          {
            this.regionIndex = 1;
            var element = document.getElementById('myElementId');
            element.scrollIntoView({ behavior: "smooth" });
          }
          break;
        default:
          break;
      }
    }
  },
};
</script>
<style scoped lang='less'>
.paystatus{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 347px;
  padding: 51px 20px;
  img{
    width: 60px;
height: 60px;
  }
  .paystatus_button{
    border: none;
    width: 100%;
    border-radius: 110px;
background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
font-size: 16px;
font-weight: 700;
color: #000  !important;
  }
  .paystatus_text{
   
     margin-top: 18px;
     margin-bottom: 75px;
    font-size: 18px;
font-weight: 700;
color: rgba(34, 34, 34, 1);
  }
}
.paypop {
  width: 100%;

  .title {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 19px 20px;
    color: rgba(34, 34, 34, 1);
    border-bottom: 1px solid rgba(242, 242, 242, 1);
  }

  .paypop_cont {
    padding: 40px 21px;

    .button {
      margin-top: 23px;
      height: 48px;
      opacity: 1;
      border-radius: 110px;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      color: #000;
    }

    .banlace {
      padding-bottom: 7px;
      border-bottom: 1px solid rgba(242, 242, 242, 1);
      display: flex;

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .banlace_box {
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
      }
    }

    .paypop_checks {
      width: 100%;

      .paypop_checks_box {
        padding: 14px 19px;
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;

        border: 1px solid rgba(230, 230, 230, 1);
        margin-bottom: 14px;

        .checkbox {
          width: 14px;
          height: 14px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.nftcenter {
  padding-top: 52px;
  padding-bottom: 44px;
}

.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nftlist {
  width: 100%;
  padding: 0 20px;

  .nftlist_li {
    padding: 25px 16px 20px 16px;
    width: 100%;
    opacity: 1;
    border-radius: 10px;
    background: rgba(27, 31, 46, 1);
    margin-bottom: 20px;
    border: 1px solid rgba(52, 247, 244, 0.49);

    .button_active {
      border-radius: 53px !important;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%) !important;
      color: #000 !important;
    }

    .nftlist_li_button {
      margin-top: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      height: 44px;
      opacity: 1;
      border-radius: 10px;
      background: rgba(0, 0, 0, 1);
    }

    .nftlist_li_cont {
      display: flex;
      align-items: center;

      .li_right {
        .poptuct {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 700;
        }

        .li_right_name {
          font-size: 14px;
          font-weight: 700;
          color: #fff;
          margin-bottom: 3px;
        }

        .li_right_pop {
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          margin-top: 6px;
        }
      }

      .li_img {
        margin-right: 18px;
        width: 84px;
        height: 110px;
      }
    }
  }
}

.functional_region {
  margin-top: 36px;
  padding: 10px 20px 0;
  padding-top: 0;

  .region {
    display: flex;
    width: 100%;
    line-height: 22px;
    font-size: 16px;
    border-radius: 30px;
    padding: 3px;
    border: 1px solid rgba(52, 247, 244, 0.49);
    font-family: PingFangSC-Semibold, PingFang SC;
    color: rgba(158, 156, 163, 1);
    font-weight: 600;
    // background:  rgba(69, 131, 255, 1);
    border-radius: 34px;
    // margin: 20px 0;
    margin-bottom: 20px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      text-align: center;
      // width: 192rpx;
      padding: 10px 0;
    }

    .special {
      // width: 50%;
      color: #000;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
      // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
      border-radius: 34px;
    }
  }
}

.dotdlist {
  border-radius: 31px;
  padding: 0 20px;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);

  .dotdlist_li {
    margin-bottom: 12px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .dotdlist_li_index {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      margin-right: 18px;
    }

    .dotdlist_li_box {
      border-radius: 31px;
      padding: 13px 19px;
      width: 274px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(27, 31, 46, 1);
    }
  }
}

.dotdbox {
  margin-top: 34px;
  margin-bottom: 14px;
  width: 100%;
  background: url('~@/assets/imgsnowball/dotd_bg.png') no-repeat;
  background-size: cover;

  .dotdbox_title {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dotdbox_cont {
    border-radius: 4px;
    margin: 0 auto;
    width: 339px;
    height: 199px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(27, 31, 46, 0.9);

    border: 1px solid rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: space-around;

    .dotdbox_cont_level {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .level_user {
        margin-top: 6px;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
      }

      .level_index {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
      }

      .level_logo {
        margin-top: 6px;
        margin-bottom: 5px;
        width: 32px;
        height: 36px;
      }

      .level_pop {
        color: rgba(0, 0, 0, 1);
        border-radius: 26px;
        background: rgba(102, 206, 253, 1);
        text-align: center;
        padding: 2px 8px;
        font-size: 12px;
      }
    }
  }
}

.lookboxs {
  display: flex;
  width: 335px;
  margin: 0 auto;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;

  .lookbox {
    position: relative;
    background-clip: padding-box;
    width: 103.77px;
   padding-bottom: 6px;
    border: 1px solid rgba(52, 247, 244, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    .abs_look {
      position: absolute;
      right: 6px;
      top: 6px;
      padding: 3px;
      border-radius: 4px;
      background: rgba(52, 247, 244, 1);
      color: rgba(0, 0, 0, 1);
      font-size: 12px;
    }

    .box {
      width: 88.09px;
    padding: 16px 0 9px 0;
      display: flex;
      border-radius: 8px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: linear-gradient(0deg, rgba(52, 247, 244, 0.4) 0%, rgba(13, 8, 26, 0) 100%);
    }

    .box_num {
      font-size: 18px;
      color: rgba(255, 255, 255, 1);
    }

    .box_title {
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
    }

    img {
      margin-top: 10px;
      margin-bottom: 14px;
      width: 26px;
      height: 26px;
    }
  }
}

.nftcenter_sum {
  padding: 13px 17px;
  width: 335px;

  margin: 0 auto;
  border-radius: 8px;
  background: linear-gradient(138.02deg, rgba(29, 145, 143, 1) 0%, rgba(102, 61, 184, 1) 100%);

  .button {
    width: 53px;
    height: 30px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(102, 61, 184, 1);
  }

  .sumbox_img {
    width: 93px;
    height: 92px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sumbox {
    font-size: 12px;

    .sumbox_title {
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 4px;
    }

    .sumbox_num {
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>