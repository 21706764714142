<template>
  <div style=" background:#4C7EF9;">
    <div style="display: flex; width: 100%; justify-content: center;   align-items: center; position: relative;">
      <topbutton router="snowguan" style="position: absolute; left: 20px; top: 20px;"></topbutton>
      <p class="s_title">{{ $t('lang.d38') }}</p>
    </div>
    <div class="shandui" style="border-radius: 20px 20px 0  0;">


      <div class="duihuan">
        <div class="line1">
          <div>{{ $t('lang.d39') }}</div>
          <div>{{ $t('lang.d40') }}</div>
        </div>
        <div class="line2">
          <div class="xuan" @click="selectshow1()">
            <van-icon name="arrow" class="arrow" />
            {{ t1_name }}
          </div>
          <img src="@/assets/imgsnowball/zhuanhuan.png" alt="" v-if="both_way == 0" style="opacity: 0.4;"
            @click="qiehuantoken">
          <img src="@/assets/imgsnowball/zhuanhuan.png" alt="" @click="qiehuantoken" v-else-if="both_way == 1"
            style="opacity: 1;">
          <div class="xuan" @click="selectshow2()">
            <van-icon name="arrow" class="arrow" />
            {{ t2_name }}
          </div>
        </div>
        <p class="shuliang">{{ $t('lang.d41') }}</p>
        <div :class="oparty || toInput ? ' line3_active line3' : 'line3'">
          <van-field type="number" v-model="toInput" input-align="left" @focus="opartyfun" @blur="opartyfalesun"
            @input="" placeholder="0" class="van-field" />
          <div class="l_line"></div>
          <div class="quanbu" @click="gettokenbannce">{{ $t('lang.d42') }}</div>
        </div>
        <p class="zuiduo">
          {{ $t('lang.d30') }}{{ bance }}
        </p>
        <div class="line4">
          <div class="title">{{ $t('lang.d44') }}</div>
          <div class="money">{{ fee_rate }}</div>
        </div>
        <div class="line4">
          <div class="title">{{ $t('lang.d45') }}</div>
          <div class="money">{{ showmap.daozhang }}</div>
        </div>
        <div class="line5_active" v-if="showbutton" @click="shanduifun">{{ $t('lang.d48') }}</div>
        <div class="line5" v-else>{{ $t('lang.d48') }}</div>

      </div>
      <p class="jilu">{{ $t('lang.d47') }}</p>
      <div class="jilu_box">
        <div class="c_wlist" v-if="shanduilist.length == 0">
          <img src="@/assets/imgsnowball/wujilu.png" alt="">
          <p>{{ $t('lang.d57') }}</p>
        </div>
        <div class="box" v-for="item in shanduilist" v-else>
          <div class="box1">
            <span class="span1">{{ item.t1_name }}{{ $t('lang.swap77') }}{{ item.t2_name }}</span>
            <span class="span2">＋{{ item.exchange_amount }}</span>
          </div>
          <div class="box2">
            <span>{{ $t('lang.d46') }}{{ item.amount }}</span>
            <span>{{ item.created_time }}</span>
          </div>
        </div>

      </div>
      <van-popup v-model="tonzhenshow1" round position="bottom" class="vantonzhenshow">
        <div class="t_li" v-for="item in tokenlist" @click="seclettokenfun1(item.token_name, item.token_id)">
          <img :src="`${imgurl}${item.logo}`" alt="">
          <span> {{ item.token_name }}</span>
        </div>

        <div class="quxiao">
          <span @click="tonzhenshow1 = false">{{ $t('lang.swap154') }}</span>
        </div>
      </van-popup>
      <van-popup v-model="tonzhenshow2" round position="bottom" class="vantonzhenshow">
        <div class="t_li" v-for="item in map_token" @click="seclettokenfun2(item)">
          <img :src="`${imgurl}${item.t2_logo}`" alt="">
          <span> {{ item.t2_name }}</span>
        </div>

        <div class="quxiao">
          <span @click="tonzhenshow2 = false">{{ $t('lang.swap154') }}</span>
        </div>
      </van-popup>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import Web3 from 'web3';
import { shandui, bizhong, homedata, moneymask } from '@/api/index';
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {
    topbutton
  },
  data() {
    return {
      oparty: false,
      select: "",
      t1_id: "",
      t2_id: "",
      t1_name: this.$t('lang.d21'),
      imgurl: "",
      t2_name: this.$t('lang.d21'),
      toInput: "",
      zuiduo: "",
      shui: "",
      daozhang: "",
      shanduilist: [],
      tokenlist: [],
      exchange_map: {},
      map_token: [],
      tokensmap: {},
      both_way: 0,
      showmap: {
        mapshu: "",
        shui: "",
        daozhang: ""
      },
      tonzhenshow1: false,
      tonzhenshow2: false,
      bance: "",
      fee_rate: ""
    }
  },
  watch: {
    account() {
      //获取账号
      this.init();
    },
    lang() {
      this.init();
      if (this.t1_name != this.$t('lang.d21')) {
        this.t1_name = this.$t('lang.d21')
      }
      if (this.t2_name != this.$t('lang.d21')) {
        this.t2_name = this.$t('lang.d21')
      }
    },
    t1_id() {
      this.getrate()
      let data = {
        token_id: this.t1_id
      }
      moneymask.getwalletgetinfo(data, this.account).then(res => {
        this.bance = res.data.usable

      })
    },
    t2_id() {
      this.getrate()
    },
    toInput() {
      if (this.toInput == "") {
        this.showmap.mapshu = ""
        this.showmap.shui = ""
        this.showmap.daozhang = ""
      }
      this.getrate()
    }
  },
  computed: {
    ...mapState(['account', 'lang']),
    showbutton() {
      return !!this.t1_id && !!this.toInput && !!this.t2_id;
    }
  },
  created() {
    this.init();
  },
  methods: {
    selectshow1() {

      this.tonzhenshow1 = true

    },
    selectshow2() {
      if (this.t1_id != "") {
        this.tonzhenshow2 = true
      } else {
        this.$toast("请先选择支付通证");
      }
    },
    opartyfun() {
      this.oparty = true
      console.log(222);
    },
    opartyfalesun() {
      this.oparty = false
      console.log(111);
    },
    init() {
      if (this.account) {
        this.shanlist()
        this.shancond()
        this.getimgurl()
      }

      // this.getbizhong()
    },
    qiehuantoken() {
      if (this.t2_id != "") {
        if (this.both_way == 0) {
          this.$toast("此通证禁止双向兑换");
        } else if (this.both_way == 1) {
          var t1id = this.t1_id;
          var t1name = this.t1_name;
          this.t1_id = this.t2_id;
          this.t1_name = this.t2_name;
          this.t2_id = t1id;
          this.t2_name = t1name;
          this.gettoken1()
        } else {

        }
      }


    },
    seclettokenfun1(name, id) {
      this.tonzhenshow1 = false;


      if (this.t2_id == id) {
        this.$toast("不能选择相同的通证");
      } else {
        this.t1_id = id;
        this.t1_name = name;
        this.gettoken1()

        if (this.t2_id != "") {
          var iscun = true
          for (var i = 0; i < this.map_token.length; i++) {
            if (this.map_token[i].t2_name == this.t2_name) {
              iscun = false
              this.t2_id = this.map_token[i].t2_id;
              this.t2_name = this.map_token[i].t2_name
              this.tokensmap = this.map_token[i]
              this.both_way = this.map_token[i].both_way;
            }
          }
          if (iscun) {
            this.t2_id = ""
            this.t2_name = this.$t('lang.d21')
            this.tokensmap = []
            this.both_way = 0
          }
        }
        this.getrate()
      }







    },
    seclettokenfun2(item) {
      this.tonzhenshow2 = false
      this.t2_id = item.t2_id;
      this.t2_name = item.t2_name
      this.tokensmap = item
      this.both_way = item.both_way;
      console.log(this.both_way);
      console.log(item);
    },
    addlistentt1id() {

    },
    async shanduifun() {
      let web3 = new Web3(window.ethereum);
      const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Exchange'), this.account)
      console.log("sign", sign);
      let data = {
        t1_id: this.t1_id,
        t2_id: this.t2_id,
        amount: parseFloat(this.toInput),
        signature: sign,
      }
      await shandui.postexchangedo(data, this.account).then(res => {
        console.log(res);
        this.$toast(res.msg);
        this.init()
      })
    },

    shancond() {
      shandui.getcondition(this.account).then(res => {
        console.log(res);
        this.tokenlist = res.data.token_list
        this.exchange_map = res.data.exchange_map
      })
    },
    shanlist() {
      shandui.getexchangelist(this.account).then(res => {
        console.log(res);
        this.shanduilist = res.data.list
      })
    },
    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.imgurl = res.data.image_domain
      })
    },
    // getbizhong (){
    //   bizhong.gettokenrechargeconfigs(this.account).then(res =>{
    //             console.log(res);
    //             this.tokenlist=res.data;
    //         })

    // }
    gettokenbannce() {
      if (this.t1_id != "") {
        let data = {
          token_id: this.t1_id
        }
        moneymask.getwalletgetinfo(data, this.account).then(res => {
          this.toInput = res.data.usable

        })
      }

    },
    getbance() {

    },
    gettoken1() {
      if (this.t1_id != "") {
        for (const key in this.exchange_map) {
          console.log(key);
          if (this.t1_name == key) {

            this.map_token = this.exchange_map[key]
            console.log("maptoken", this.map_token);

          }
        }


      }

    },
    gettoken2() {

    },
    numMulti(num1, num2) {
      var baseNum = 0;
      try {
        baseNum += String(num1).split(".")[1].length;
      } catch (e) {

      }
      try {
        baseNum += String(num2).split(".")[1].length;
      } catch (e) {

      }
      return Number(String(num1).replace(".", "")) * Number(String(num2).replace(".", "")) / Math.pow(10, baseNum);
    },
    getrate() {
      if (this.t1_id != "" && this.t2_id != "" && this.toInput != "") {
        this.showmap.mapshu = this.numMulti(this.tokensmap.exchange_rate, this.toInput)
        this.showmap.shui = this.numMulti(this.showmap.mapshu, this.tokensmap.fee_rate)
        this.fee_rate = `${this.tokensmap.fee_rate * 100}%`
        this.showmap.daozhang = this.showmap.mapshu - this.showmap.shui
      }
    }
  }
}
</script>

<style lang="less" scoped>
.s_title {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  font-family: "PingFang";
}

.shandui {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: var(--text-color);

}

.duihuan {
  width: 100%;

  border-radius: 20px;
  background: #F7F7F7;
  box-sizing: border-box;
  padding: 21px 16px;

  .line1 {
    display: flex;
    width: 100%;

    div {
      width: 50%;
      opacity: 0.6;
      color: #000;
      font-size: 12px;
      font-weight: 700;
      font-family: "PingFang";
    }
  }

  .line2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    img {
      width: 20px;
      height: 20px;
    }

    .xuan {
      width: 116px;
      height: 44px;
      border-radius: 30px;
      position: relative;
      background: var(--text-color);
      box-sizing: border-box;
      padding: 12px 20px;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      font-family: "PingFang";

      .arrow {
        position: absolute;
        color: #000;
        right: 10px;
        top: 15px;

      }
    }
  }

  .shuliang {
    opacity: 0.6;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: "PingFang";
  }

  .line3 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    border-radius: 10px;
    opacity: 0.4;
    border: 1px solid #32a0ffff;

    .l_line {
      width: 1px;
      height: 13px;
      flex-shrink: 0;
      background: #32a0ffff;
    }

    .quanbu {
      color: #32a0ffff;
      font-size: 12px;
      font-weight: 700;
      font-family: "PingFang";
      text-align: center;
      flex: 1;

    }
  }
}

.van-field {
  width: 70%;


  height: 100%;
  border-radius: 10px;

  font-weight: bold;
  background: #F7F7F7 !important;
  padding: 12px 14px !important;

  /deep/ .van-field__control {
    font-size: 18px;
    color: #000;
  }

}

.zuiduo {
  opacity: 0.4;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  font-family: "PingFang";
}

.line4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;

  .title {
    opacity: 0.6;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: "PingFang";
  }

  .money {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: "PingFang";
  }
}

.line5 {
  margin-top: 40px;
  width: 100%;
  text-align: center;
  line-height: 55px;
  height: 55px;
  border-radius: 65px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
  font-family: "PingFang";
  background: #4583FF;
}

.line5_active {
  margin-top: 40px;
  width: 100%;
  text-align: center;
  line-height: 55px;
  height: 55px;
  border-radius: 10px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
  font-family: "PingFang";
  background: linear-gradient(160deg, #06e2e1ff 0%, #32a0ffff 75%);
}

.jilu {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  font-family: "PingFang";
}

.jilu_box {
  width: 100%;

  border-radius: 20px;
  background: #F7F7F7;
  padding: 20px 17px;

  .box {
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #484A4E;

    div {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;

    }

    .box1 {
      margin-bottom: 10px;

      .span1 {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        font-family: "PingFang";
      }

      .span2 {
        color: #39dba3ff;
        font-size: 14px;
        font-weight: 700;
        font-family: "PingFang";
      }
    }

    .box2 {
      width: 90%;
      color: #84868A;
      font-size: 12px;
      font-weight: 500;
      font-family: "PingFang";
    }
  }
}

.box:last-child {
  border: none;
}

.vantonzhenshow {
  padding: 20px;

  .t_li {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #0000000f;

    img {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      border-radius: 50%;
    }
  }

  .quxiao {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-family: "PingFang SC";
  }
}

.line3_active {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  border-radius: 10px;
  opacity: 1 !important;
  border: 1px solid #32a0ffff;
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;
  background: #F7F7F7;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 92px;

  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
  }
}
</style>